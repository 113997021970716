import moment from 'moment';
import { Markdown } from '../markdown';
import { ContentfulLivePreview } from "@contentful/live-preview";
import { cn } from '@/lib/utils';

export interface TextWithHeaderSectionModel {
    sys: {
        id: string;
    };
    __typename: string;
    headline: string | undefined;
    subHeadline: ContentfulRichTextModel | undefined;
    publicationDate: Date | undefined;
    authorName: string | undefined;
    logo: ContentfulImageModel | undefined;
    articleText: ContentfulRichTextModel | undefined;
    colorScheme: "Racing Green" | "Sand" | "Dark" | "Light Green" | undefined;
}


const TextWithHeaderSection: React.FC<TextWithHeaderSectionModel> = (textWithHeaderSectionModel) => {
    let bgColor = "bg-primary-200";
    let textColor = "text-grey-900"

    switch (textWithHeaderSectionModel.colorScheme) {
        case "Dark":
            bgColor = "bg-grey-900"
            textColor = "text-secondary-200"
            break;
        case "Racing Green":
            bgColor = "bg-primary-800"
            textColor = "text-secondary-200"
            break;
        case "Sand":
            bgColor = "bg-secondary-200"
            textColor = "text-grey-900"
            break;
        default:
            break;
    }

    let formattedDate = null;
     
    if (textWithHeaderSectionModel.publicationDate != undefined) {
        formattedDate = (moment(textWithHeaderSectionModel.publicationDate)).format('MMM DD, YYYY')
    }

    return (
        <div className={cn("flex flex-col items-center w-full py-10 lg:pt-20 lg:pb-10", bgColor)}>
            <div className="flex flex-col w-full max-w-[1440px] px-6 lg:px-[155px] ">
                <div className="flex flex-col-reverse justify-start items-start lg:flex-row w-full lg:justify-between lg:items-top">
                    { textWithHeaderSectionModel.headline && (
                        <h1 
                        {...ContentfulLivePreview.getProps({
                            entryId: textWithHeaderSectionModel.sys.id,
                            fieldId: 'headline',
                            locale: 'en-US',
                        })}
                        className={cn(
                            "font-recoleta font-light text-[60px] leading-[60px] mb-10 lg:mb-0", 
                            textColor,
                            textWithHeaderSectionModel.subHeadline && "mb-3"
                        )}>{textWithHeaderSectionModel.headline}</h1>
                    )}
                    
                    {textWithHeaderSectionModel.logo && textWithHeaderSectionModel.logo.url && (
                            <img
                                {...ContentfulLivePreview.getProps({
                                    entryId: textWithHeaderSectionModel.sys.id,
                                    fieldId: 'logo',
                                    locale: 'en-US',
                                })}
                                className="h-[50px] object-contain mb-6"
                                src={textWithHeaderSectionModel.logo.url} />
                        )
                    }
                </div>
                {textWithHeaderSectionModel.subHeadline && (
                    <div 
                        {...ContentfulLivePreview.getProps({
                            entryId: textWithHeaderSectionModel.sys.id,
                            fieldId: 'subHeadline',
                            locale: 'en-US',
                        })}
                        >
                        <Markdown 
                            forcedBlock="heading-5"
                            className={cn("opacity-80 mb-6", textColor)}
                            content={textWithHeaderSectionModel.subHeadline}/>
                    </div>
                )}
                <div className={cn(
                    "flex flex-row w-full gap-10 mb-6 lg:mb-6", 
                    textWithHeaderSectionModel.articleText?.json && "lg:mb-10"
                    )}>
                    {formattedDate && (
                        <h6
                            className={cn("font-roboto text-[16px] opacity-80", textColor)}
                            {...ContentfulLivePreview.getProps({
                                entryId: textWithHeaderSectionModel.sys.id,
                                fieldId: 'publicationDate',
                                locale: 'en-US',
                            })}
                        >
                            {formattedDate}
                        </h6>
                    )}
                    {textWithHeaderSectionModel.authorName && (
                        <h6
                            className={cn("font-roboto text-[16px] opacity-80", textColor)}
                            {...ContentfulLivePreview.getProps({
                                entryId: textWithHeaderSectionModel.sys.id,
                                fieldId: 'authorName',
                                locale: 'en-US',
                            })}
                        >
                        {textWithHeaderSectionModel.authorName}
                    </h6>
                    )}
                    
                </div>
                { textWithHeaderSectionModel.articleText && ( 
                    <div 
                        {...ContentfulLivePreview.getProps({
                            entryId: textWithHeaderSectionModel.sys.id,
                            fieldId: 'articleText',
                            locale: 'en-US',
                        })}
                        className={cn("flex flex-col gap-10 font-roboto", textColor)}>
                        <Markdown content={textWithHeaderSectionModel.articleText}/>
                    </div>
                )}

            </div>
        </div>
    );
};

export default TextWithHeaderSection;