import { ButtonModel, CtaSectionModel } from "@/lib/models/ContenfulSections";
import { Markdown } from "../markdown";
import { ContentfulLivePreview } from "@contentful/live-preview";
import ButtonComponent from "../ButtonComponent";
import { cn } from "@/lib/utils";
import { Heading } from "@/components/ui/Heading";

const CtaSectionComponent: React.FC<CtaSectionModel> = (ctaSectionModel) => {
    let bgColor = "bg-primary-200";
    let textColor = "text-grey-900"
    let primaryButtonVariant: "highlightFilled" | "lightFilled" | "darkFilled" | "highlightOutline" | "lightOutline" | "darkOutline" | "highlightText" | "lightText" | "darkText" | undefined = "highlightFilled";
    let secondaryButtonVariant: "highlightFilled" | "lightFilled" | "darkFilled" | "highlightOutline" | "lightOutline" | "darkOutline" | "highlightText" | "lightText" | "darkText" | undefined = "highlightOutline";

    switch (ctaSectionModel.colorScheme) {
        case "Dark":
            bgColor = "bg-grey-900"
            textColor = "text-secondary-200"
            primaryButtonVariant = "darkFilled"
            secondaryButtonVariant = "darkOutline"
            break;
        case "Racing Green":
            bgColor = "bg-primary-800"
            textColor = "text-secondary-200"
            primaryButtonVariant = "darkFilled"
            secondaryButtonVariant = "darkOutline"
            break;
        case "Sand":
            bgColor = "bg-secondary-200"
            textColor = "text-grey-900"
            primaryButtonVariant = "lightFilled"
            secondaryButtonVariant = "lightOutline"
            break;
        default:
            break;
    }

    return (
        <div
            className={
                cn("cta_section flex flex-col items-center w-full py-10 px-6",
                bgColor)}>
            <div className="z-10 max-w-[1440px] w-full flex flex-col items-center text-background">
                {ctaSectionModel.title &&
                    <Heading
                        typeVariant="h2"
                        styleVariant="smallHeadline"
                        {...ContentfulLivePreview.getProps({
                            entryId: ctaSectionModel.sys.id,
                            fieldId: "title",
                            locale: "en-US",
                        })}
                        className={cn("text-center px-6 lg:max-w-[67%]", textColor )}
                    >
                        {ctaSectionModel.title}
                    </Heading>
                }
                {ctaSectionModel.paragraph &&
                    <div
                        {...ContentfulLivePreview.getProps({
                            entryId: ctaSectionModel.sys.id,
                            fieldId: "paragraph",
                            locale: "en-US",
                        })}
                        className="pt-6">
                        <Markdown
                            className={cn("text-center lg:max-w-2xl text-gray-900", textColor)}
                            headingStyle="largeParagraph"
                            forcedBlock="heading-4"
                            content={ctaSectionModel.paragraph} />
                    </div>
                }
                <div className="flex flex-row mt-6 gap-6 items-center">
                    <ButtonComponent 
                        contenfulEntryId={ctaSectionModel.sys.id}
                        contentfulFieldId={"primaryCta"}
                        buttonVariant={primaryButtonVariant}
                        buttonSize={"lg"} 
                        buttonModel={ctaSectionModel.primaryCta} />
                    <ButtonComponent 
                        contenfulEntryId={ctaSectionModel.sys.id}
                        contentfulFieldId={"secondaryCta"}
                        buttonVariant={secondaryButtonVariant}
                        buttonSize={"lg"} 
                        buttonModel={ctaSectionModel.secondaryCta} />
                </div>
                <div className="flex flex-col items-center w-full px-6 lg:max-w-[67%]">
                    {ctaSectionModel.hookSubtitleText && (
                        <div 
                        className="flex flex-col w-full items-center"
                            {...ContentfulLivePreview.getProps({
                                entryId: ctaSectionModel.sys.id,
                                fieldId: "hookSubtitleText",
                                locale: "en-US",
                            })}
                        >
                            <Markdown
                                content={ctaSectionModel.hookSubtitleText}
                                headingStyle="mainParagraph"
                                forcedBlock="heading-5"
                                className={cn("max-w-[50%] text-center pt-4", textColor)}
                            />
                        </div>
                        
                    )}
                </div>
            </div>
        </div>
    );
};

export default CtaSectionComponent;