import { ContentfulLivePreview } from "@contentful/live-preview";
import { Markdown } from "../markdown";
import { cn } from "@/lib/utils";
import { Heading } from "@/components/ui/Heading";

export interface NumberHookSectionModel {
  sys: {
    id: string;
  };
  __typename: string;
  numberHook: string;
  paragraph: ContentfulRichTextModel;
  colorScheme: "Racing Green" | "Sand" | "Dark" | "Light Green" | undefined;
}

const NumberHookSectionComponent: React.FC<NumberHookSectionModel> = (numberHookSectionModel) => {
  let bgColor = "bg-primary-200";
  let textColor = "text-grey-900"

  switch (numberHookSectionModel.colorScheme) {
      case "Dark":
          bgColor = "bg-grey-900"
          textColor = "text-secondary-200"
          break;
      case "Racing Green":
          bgColor = "bg-primary-800"
          textColor = "text-secondary-200"
          break;
      case "Sand":
          bgColor = "bg-secondary-200"
          textColor = "text-grey-900"
          break;
      default:
          break;
  }

  return (
    <div className={cn("flex flex-col w-full items-center", bgColor)}>
      <div className="flex flex-col lg:flex-row w-full max-w-[1440px] py-6 lg:py-10 px-6 lg:px-[155px] gap-4 lg:gap-10">
        <Heading
          typeVariant="h6"
          styleVariant="extraLarge"
          {...ContentfulLivePreview.getProps({
            entryId: numberHookSectionModel.sys.id,
            fieldId: "sectionTitle",
            locale: "en-US",
          })}
          className={cn("", textColor)}>
          {numberHookSectionModel.numberHook}
        </Heading>
        <div 
          {...ContentfulLivePreview.getProps({
            entryId: numberHookSectionModel.sys.id,
            fieldId: "paragraph",
            locale: "en-US",
          })}
          className="flex flex-row items-center">
          <Markdown
              forcedBlock="heading-4"
              headingStyle="smallHeadline"
              className={cn("font-recoleta text-[32px] lg:text-[46px]", textColor)}
              content={numberHookSectionModel.paragraph}/>
        </div>

      </div>
    </div>
  )
};

export default NumberHookSectionComponent;