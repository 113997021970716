import { ContentfulLivePreview } from "@contentful/live-preview";
import { Markdown } from "../markdown";

export interface SplitVerticalNarrativeSectionModel {
    sys: {
        id: string;
    };
    __typename: string;
    sectionHeadline: ContentfulRichTextModel;
    sectionImage: ContentfulImageModel;
    paragraphText: ContentfulRichTextModel;
}

const SplitVerticalNarrativeSectionComponent: React.FC<SplitVerticalNarrativeSectionModel> = (splitVerticalNarrativeSectionModel) => {
    return (
        <div className="flex flex-col w-full items-center">
            <div className="flex flex-col lg:grid lg:grid-cols-12 gap-4 w-full max-w-[1440px] py-10 px-6">
                <div className="col-span-3 col-start-1 lg:col-span-3 lg:col-start-2">
                    <div
                        {...ContentfulLivePreview.getProps({
                            entryId: splitVerticalNarrativeSectionModel.sys.id,
                            fieldId: 'sectionHeadline',
                            locale: 'en-US',
                        })}
                    >
                        <Markdown
                            className=" text-secondary-200 leading-[46px]"
                            forcedBlock="heading-4"
                            content={splitVerticalNarrativeSectionModel.sectionHeadline} />
                    </div>

                    {splitVerticalNarrativeSectionModel.sectionImage && splitVerticalNarrativeSectionModel.sectionImage.url && (
                        <img
                            {...ContentfulLivePreview.getProps({
                                entryId: splitVerticalNarrativeSectionModel.sys.id,
                                fieldId: 'sectionImage',
                                locale: 'en-US',
                            })}
                            src={splitVerticalNarrativeSectionModel.sectionImage.url}
                            className="py-4 lg:py-0 w-full max-w-[272px] lg:pt-8" />

                    )}
                </div>
                <div 
                    {...ContentfulLivePreview.getProps({
                        entryId: splitVerticalNarrativeSectionModel.sys.id,
                        fieldId: 'paragraphText',
                        locale: 'en-US',
                    })}
                    className="flex flex-col w-full lg:col-span-7 lg:col-start-5 items-center">
                    <Markdown
                        forcedBlock="paragraph"
                        bodyVariant="body1"
                        className="font-roboto text-secondary-200"
                        content={splitVerticalNarrativeSectionModel.paragraphText} />
                </div>

            </div>
        </div>
    )
};

export default SplitVerticalNarrativeSectionComponent;