import {Markdown} from "../markdown";
import { Paragraph } from "@/components/ui/texts/paragraph";
import { cn } from "@/lib/utils";
import { ContentfulLivePreview } from '@contentful/live-preview';
import Image from "next/image";


export interface QuoteSectionSectionModel {
    sys: {
        id: string;
    };
    __typename: string;
    title: string;
    quote: ContentfulRichTextModel;
    avatar: ContentfulImageModel | undefined;
    name: string;
    workTitle: string;
    backgroundImage: ContentfulImageModel | undefined;
    colorScheme: "Racing Green" | "Sand" | "Dark" | "Light Green" | undefined;
}


const QuoteSectionComponent: React.FC<QuoteSectionSectionModel> = (quoteSectionSectionModel) => {
    let bgColor = "bg-primary-200";
    let textColor = "text-grey-900"

    switch (quoteSectionSectionModel.colorScheme) {
        case "Dark":
            bgColor = "bg-grey-900"
            textColor = "text-secondary-200"
            break;
        case "Racing Green":
            bgColor = "bg-primary-800"
            textColor = "text-secondary-200"
            break;
        case "Sand":
            bgColor = "bg-secondary-200"
            textColor = "text-grey-900"
            break;
        default:
            break;
    }

    const getContentLength = (content: any) => {
        if (
            content && 
            content.content &&
            content.content.length > 0 &&
            content.content[0].content &&
            content.content[0].content.length > 0
            ) {
            return content.content[0].content[0].value.length
        }
     return null;
    }

    const contentLength = getContentLength(quoteSectionSectionModel.quote.json);
    
    return (
        <div className={cn("quote_section flex flex-col items-center w-full py-10", bgColor)}>
            <div className="z-10 max-w-[1440px] w-full flex flex-col items-center">
                <div
                    {...ContentfulLivePreview.getProps({
                        entryId: quoteSectionSectionModel.sys.id,
                        fieldId: 'quote',
                        locale: 'en-US',
                    })}
                    className="flex flex-col items-center w-full px-6 lg:max-w-[66%] mb-10"
                    >
                    { contentLength < 100 && (
                        <Markdown
                            className={cn("text-center", textColor)}
                            forcedBlock="heading-3"
                            content={quoteSectionSectionModel.quote}/>

                            
                    ) || contentLength < 200 && (
                        <Markdown
                            className={cn("text-center w-full", textColor)}
                            forcedBlock="heading-4"
                            content={quoteSectionSectionModel.quote}/>
                    )  || (
                        <Markdown
                            className={cn("text-center w-full", textColor)}
                            forcedBlock="paragraph"
                            bodyVariant="body1"
                            content={quoteSectionSectionModel.quote}/>
                    )}
                </div>

                {quoteSectionSectionModel.avatar && quoteSectionSectionModel.avatar.url && (
                    <Image
                        {...ContentfulLivePreview.getProps({
                            entryId: quoteSectionSectionModel.sys.id,
                            fieldId: 'avatar',
                            locale: 'en-US',
                        })}
                        src={quoteSectionSectionModel.avatar.url} 
                        className="h-[64px] w-[64px] mb-6 rounded-full aspect-[1/1] object-cover object-top"
                        height={64}
                        width={64}
                        alt={"Logo for quote"}
                    />
                )}
                <Paragraph 
                    {...ContentfulLivePreview.getProps({
                        entryId: quoteSectionSectionModel.sys.id,
                        fieldId: 'name',
                        locale: 'en-US',
                        })}
                    variant={"body2"}
                    className={cn("text-center", textColor)}>
                    {quoteSectionSectionModel.name}
                </Paragraph>
                <Paragraph 
                    {...ContentfulLivePreview.getProps({
                    entryId: quoteSectionSectionModel.sys.id,
                    fieldId: "workTitle",
                    locale: "en-US",
                    })}
                    variant={"body2"}
                    className={cn("text-center mt-2", textColor)}>
                    {quoteSectionSectionModel.workTitle}
                </Paragraph>
            </div>
        </div>
    );
};

export default QuoteSectionComponent;