import { Markdown } from "../markdown";
import ButtonComponent from "../ButtonComponent";
import Link from "next/link";
import { Button } from "@/components/ui/button";
import { ContentfulLivePreview } from "@contentful/live-preview";
import React from "react";
import moment from "moment";
import { Paragraph } from "@/components/ui/texts/paragraph";
import { cn } from "@/lib/utils";
import Image from "next/image";

export interface CardListSectionModel {
    sys: {
        id: string;
    };
    __typename: string;
    headlineText: ContentfulRichTextModel | undefined;
    paragraph: ContentfulRichTextModel | undefined;
    cardElementsCollection: {
        items: CardListElementModel[];
    };
    colorScheme: "Racing Green" | "Bright Green" | "Sand" | "Dark" | "Light Green" | undefined;
    viewAllPage: {
        sys: {
            id: string;
        };
        slug: string | undefined;
    } | undefined;
}

export interface CardListElementModel {
    sys: {
        id: string;
    };
    __typename: string;
    headline: ContentfulRichTextModel | undefined;
    paragraphText: ContentfulRichTextModel | undefined;
    cardImage: ContentfulImageModel | undefined;
    contentTag: "Weekly" | "Interview" | "Article" | undefined;
    publicationDate: Date | undefined;
    linkedPage: {
        sys: {
            id: string;
        };
        slug: string | undefined;
    } | undefined;
}

const CardListSection: React.FC<CardListSectionModel> = (cardListSection) => {
    let bgColor = "bg-primary-200";
    let textColor = "text-grey-900"

    switch (cardListSection.colorScheme) {
        case "Dark":
            bgColor = "bg-grey-900"
            textColor = "text-secondary-200"
            break;
        case "Racing Green":
            bgColor = "bg-primary-800"
            textColor = "text-secondary-200"
            break;
        case "Bright Green":
            bgColor = "bg-primary-600"
            textColor = "text-secondary-200"
            break;
        case "Sand":
            bgColor = "bg-secondary-200"
            textColor = "text-grey-900"
            break;
        default:
            break;
    }

    return (
        <div className={cn("flex flex-col items-center w-full py-10 px-6", bgColor)}>
            <div className="flex flex-col items-center w-full max-w-[1440px] ">
                {cardListSection.headlineText &&
                <div
                    className="w-full"
                    {...ContentfulLivePreview.getProps({
                        entryId: cardListSection.sys.id,
                        fieldId: "headlineText",
                        locale: "en-US",
                    })}
                >
                    <Markdown
                        className={cn("text-center w-full",textColor)}
                        bodyVariant="body1"
                        forcedBlock="heading-3"
                        content={cardListSection.headlineText} />
                </div>

                }
                {cardListSection.paragraph &&
                    <div
                        className="flex flex-row items-center w-full max-w-[60%]"
                        {...ContentfulLivePreview.getProps({
                            entryId: cardListSection.sys.id,
                            fieldId: "paragraph",
                            locale: "en-US",
                        })}
                    >
                    <Markdown
                        className={cn("text-center w-full pt-4 ",textColor)}
                        bodyVariant="body2"
                        forcedBlock="paragraph"
                        content={cardListSection.paragraph} />
                    </div>

                }

                {cardListSection.cardElementsCollection && cardListSection.cardElementsCollection.items.length == 1 && (
                    <>
                        <div className="hidden flex-row w-full justify-center items-center mt-10 lg:flex">
                            {HorizontalCardListElement(cardListSection.cardElementsCollection.items[0])}
                        </div>
                        <div className="flex flex-row w-full justify-center items-center mt-10 lg:hidden">
                            {VerticalCardListElement(cardListSection.cardElementsCollection.items[0])}
                        </div>
                    </>
                )

                }
                {cardListSection.cardElementsCollection && cardListSection.cardElementsCollection.items.length > 1 && (
                    <>
                        <div className="flex flex-col lg:flex-row w-full max-w-[1440px] justify-center items-stretch mt-10 gap-4">
                            {cardListSection.cardElementsCollection.items.map((cardElement, index) => {
                                return VerticalCardListElement(cardElement)
                            })}
                        </div>
     
                    </>

                )}
                {cardListSection.viewAllPage && (
                    
                    <div className="flex flex-row w-full justify-center items-center mt-4">
                        <Link
                            href={cardListSection.viewAllPage?.slug ?? ""}
                            passHref
                        >
                            <Button 
                                className={cn("opacity-80", textColor)}
                                variant={"darkText"}
                            >
                                View All
                            </Button>
                        </Link>
                    </div>
                )}
            </div>

        </div>
    )
};

const TagPill: React.FC<{ className: string, tag: "Commodities" | "Freight" | "Weekly" | "Interview" | "Article" | undefined; }> = ({ className, tag }) => {
    let backgroundColor = "bg-primary-200";
    
    switch (tag) {
        case "Article":
            backgroundColor = "bg-[#ACCBE7]";
            break;
        case "Interview":
            backgroundColor = "bg-[#D2AFFF]";
            break;
        case "Weekly":
            backgroundColor = "bg-[#E3D09E]";
            break;
        case "Commodities":
            backgroundColor = "bg-[#CBE285]";
            break;
        case "Freight":
            backgroundColor = "bg-[#F5B3B1]";
            break;
        default:
            break;
    }
    return (
        <Paragraph 
            variant={"body3"}
            className={cn("text-primary-800  text-sm bg-primary-200  rounded-[8px] px-6 py-1", 
            backgroundColor,
            className
            )}>
            {tag}
        </Paragraph>
    )
}

const VerticalCardListElement: React.FC<CardListElementModel> = (cardElement) => {

    let momentDate = moment(cardElement.publicationDate);

    return (
        <div
            key={cardElement.sys.id}
            className="flex flex-col w-full max-w-[450px] bg-secondary-100 rounded-[16px] overflow-hidden">
                <div className="relative">
                    {cardElement.cardImage && cardElement.cardImage.url && (
                        <Image
                            {...ContentfulLivePreview.getProps({
                                entryId: cardElement.sys.id,
                                fieldId: "cardImage",
                                locale: "en-US",
                            })}
                            className="w-full aspect-[4/3] object-cover"
                            src={cardElement.cardImage.url}
                            width={348}
                            height={261}
                            alt={""}/>
                    )}

                    {cardElement.contentTag && (
                        <TagPill 
                            className="absolute top-4 right-4"
                            tag={cardElement.contentTag} />
                    )}
                </div>

            <div className="flex flex-row w-full justify-between px-4 pt-4 gap-6">
                {cardElement.headline && (
                    <div
                        {...ContentfulLivePreview.getProps({
                            entryId: cardElement.sys.id,
                            fieldId: "headline",
                            locale: "en-US",
                        })}
                    >
                        <Markdown
                            className=" text-grey-900 line-clamp-2"
                            forcedBlock="heading-6"
                            content={cardElement.headline} />
                    </div>

                )}
                {momentDate.isValid() && (
                    <div
                        className="flex-shrink-0"
                        {...ContentfulLivePreview.getProps({
                            entryId: cardElement.sys.id,
                            fieldId: "publicationDate",
                            locale: "en-US",
                        })}
                    >
                        <Paragraph 
                            className="text-grey-900 text-opacity-60 text-right "
                            variant={"body3"}>
                            {momentDate.format('MMM DD, YYYY')}
                        </Paragraph>
                    </div>
                    
                )}

            </div>
            {cardElement.paragraphText && (
                <div
                    {...ContentfulLivePreview.getProps({
                        entryId: cardElement.sys.id,
                        fieldId: "paragraphText",
                        locale: "en-US",
                    })}
                >
                    <Markdown
                        className=" text-grey-900 line-clamp-4 m-4 overflow-hidden"
                        forcedBlock="paragraph"
                        bodyVariant="body3"
                        content={cardElement.paragraphText} />
                    </div>

            )}
            {cardElement.linkedPage && (
                <div className="mt-auto ml-4 mb-4 pt-4">
                    <Link
                        className=""
                        {...ContentfulLivePreview.getProps({
                            entryId: cardElement.sys.id,
                            fieldId: "linkedPage",
                            locale: "en-US",
                        })}
                        href={cardElement.linkedPage?.slug ?? ""}>
                        <Button
                            variant={"highlightOutline"}
                            size={"sm"}
                        >
                            Read More
                        </Button>
                    </Link>
                </div>

            )}
        </div>
    );
}

const HorizontalCardListElement: React.FC<CardListElementModel> = (cardElement) => {
    return (
        <div key={cardElement.sys.id} className="flex flex-row gap-4 bg-secondary-200 mx-6 lg:mx-[155px] rounded-[16px] overflow-hidden">
            {cardElement.cardImage && cardElement.cardImage.url && (
                <Image
                    {...ContentfulLivePreview.getProps({
                        entryId: cardElement.sys.id,
                        fieldId: "cardImage",
                        locale: "en-US",
                    })}
                    className="w-full aspect-[4/3] object-cover  max-w-[500px]"
                    src={cardElement.cardImage.url}
                    width={524}
                    height={393}

                    alt={""}/>
            )}

            <div className="flex flex-col py-10 px-6">
                {cardElement.headline && (
                    <div
                        className="w-full"
                        {...ContentfulLivePreview.getProps({
                            entryId: cardElement.sys.id,
                            fieldId: "headline",
                            locale: "en-US",
                        })}
                    >
                        <Markdown
                            className="w-full text-grey-900 line-clamp-2"
                            forcedBlock="heading-4"
                            content={cardElement.headline} />
                    </div>
                )}
                {cardElement.paragraphText && (
                    <div
                        className="w-full"
                        {...ContentfulLivePreview.getProps({
                            entryId: cardElement.sys.id,
                            fieldId: "paragraphText",
                            locale: "en-US",
                        })}>
                        <Markdown
                            className="w-full text-grey-900 text-opacity-60 pt-6 line-clamp-3"
                            forcedBlock="paragraph"
                            bodyVariant="body1"
                            content={cardElement.paragraphText} />
                    </div>
                )}
                <div className="flex flex-col-reverse flex-grow">
                    <Link
                        className=" mt-6"
                        href={cardElement.linkedPage?.slug ?? ""}>
                        <Button
                            {...ContentfulLivePreview.getProps({
                                entryId: cardElement.sys.id,
                                fieldId: "linkedPage",
                                locale: "en-US",
                            })}
                            variant={"highlightOutline"}
                        >
                            Read More
                        </Button>
                    </Link>
                </div>

            </div>

        </div>
    )
};

export default CardListSection;