import { Paragraph } from "@/components/ui/texts/paragraph";
import { ButtonModel } from "@/lib/models/ContenfulSections";
import { cn } from "@/lib/utils";

import { Markdown } from "../markdown";
import { Button } from "@/components/ui/button";
import Image from "next/image";
import Link from "next/link";
import { ContentfulLivePreview } from "@contentful/live-preview";
import ButtonComponent from "../ButtonComponent";

export interface HorizontalHeroSectionModel {
  sys: {
    id: string;
  };
  __typename: string;
  sectionTitle: ContentfulRichTextModel | undefined;
  paragraphText: ContentfulRichTextModel | undefined;
  image: ContentfulImageModel | undefined;
  ctaButton: ButtonModel | undefined;
  textAlignment: "Left" | "Right";
}

const HorizontalHeroSectionComponent: React.FC<HorizontalHeroSectionModel> = (horizontalHeroSectionModel) => {
  return (
    <div className="flex flex-row items-center justify-center h-auto w-full bg-grey-900">
        <div className={cn(
            "flex flex-row items-center w-full py-10 max-w-[1440px] px-6 lg:px-[155px] gap-[8%]",
            horizontalHeroSectionModel.textAlignment === "Right" && "flex-row-reverse"
        )}>
            {/* Text Column */}
            <div className="flex-1 flex flex-col lg:p-4">
                {horizontalHeroSectionModel.sectionTitle && (
                    <div
                        {...ContentfulLivePreview.getProps({
                            entryId: horizontalHeroSectionModel.sys.id,
                            fieldId: "contentfulSectionTitle",
                            locale: "en-US",
                        })}
                    >
                        <Markdown 
                            className="font-recoleta lg:text-[60px] lg:leading-[60px] text-[60px] leading-[60px] text-secondary-200"
                            forcedBlock="heading-1"
                            content={horizontalHeroSectionModel.sectionTitle} />
                    </div>

                )}
                {horizontalHeroSectionModel.paragraphText && (
                    <div
                        {...ContentfulLivePreview.getProps({
                            entryId: horizontalHeroSectionModel.sys.id,
                            fieldId: "paragraphText",
                            locale: "en-US",
                        })}
                    >
                        <Markdown 
                            bodyVariant="body1"
                            className="text-secondary-200 mt-10 leading-[30px]"
                            content={horizontalHeroSectionModel.paragraphText} 
                        />
                    </div>

                )}

                <div className="flex flex-row space-x-2 mt-10">
                    <ButtonComponent 
                        className=""
                        contenfulEntryId={horizontalHeroSectionModel.sys.id}
                        contentfulFieldId={"ctaButton"}
                        buttonVariant={"darkFilled"}
                        buttonSize={"lg"} 
                        buttonModel={horizontalHeroSectionModel.ctaButton} />
                </div>
            </div>
            {/* Image Column */}
            <div className="hidden lg:flex py-14 px-11 bg-secondary-100 rounded-lg">
                {horizontalHeroSectionModel.image && horizontalHeroSectionModel.image.url && (
                    <Image
                        {...ContentfulLivePreview.getProps({
                            entryId: horizontalHeroSectionModel.sys.id,
                            fieldId: "image",
                            locale: "en-US",
                        })}
                        src={horizontalHeroSectionModel.image.url}
                        alt={""}
                        className="rounded-lg aspect-[3/4] object-cover"
                        width={355}
                        height={472}
                    />
                )}
            </div>
        </div>
    </div>
  )
}

export default HorizontalHeroSectionComponent;