import { Separator } from "@/components/ui/separator";
import { Markdown } from "../markdown";
import { ButtonModel } from "@/lib/models/ContenfulSections";
import Link from "next/link";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { ContentfulLivePreview } from "@contentful/live-preview";
import { getRandomValue } from "util";
import ButtonComponent from "../ButtonComponent";

export interface VerticalListSectionModel {
    sys: {
        id: string;
    }
    __typename: string;
    headlineText: ContentfulRichTextModel | undefined;
    subHeadline: ContentfulRichTextModel | undefined;
    listElementsCollection: {
        items: VerticalListElementModel[];
    }
    primaryCta: ButtonModel | undefined;
}

export interface VerticalListElementModel {
    sys: {
        id: string;
    }
    __typename: string;
    elementOrderTitle: string;
    elementTitle: ContentfulRichTextModel | undefined;
    elementParagraph: ContentfulRichTextModel | undefined;
}

const VerticalListSectionComponent: React.FC<VerticalListSectionModel> = (verticalListSectionModel) => {

    return (
        <div className="flex flex-col items-center w-full bg-grey-900 pt-20" >
            <div className="pb-20 flex flex-col items-center w-full max-w-[1440px] px-6 lg:px-0">
                {verticalListSectionModel.headlineText && (
                    <Markdown 
                        forcedBlock="heading-3"
                        content={verticalListSectionModel.headlineText} 
                        className={cn("text-secondary-200 w-full lg:pl-[155px] pb-6 lg:pb-20", 
                        verticalListSectionModel.subHeadline && "pb-2 lg:pb-4"    
                        )} />
                )}
                {verticalListSectionModel.subHeadline && (
                    <Markdown 
                        forcedBlock="paragraph"
                        content={verticalListSectionModel.subHeadline} 
                        className="text-secondary-200 w-full lg:pl-[155px] text-opacity-80 pb-6 lg:pb-20" />
                )}
                {verticalListSectionModel &&
                    verticalListSectionModel.listElementsCollection &&
                    verticalListSectionModel.listElementsCollection.items.map((listElement, index) => {
                        return (
                            <li
                                key={listElement.sys.id+index}
                                {...ContentfulLivePreview.getProps({
                                    entryId: verticalListSectionModel.sys.id,
                                    fieldId: 'listElements',
                                    locale: 'en-US',
                                })}

                                className="flex flex-col lg:grid lg:grid-cols-12 w-full mt-6 first:mt-0">
                                <div className="col-start-1 col-span-3 lg:col-start-3 lg:col-span-2">
                                    <h3 
                                        {...ContentfulLivePreview.getProps({
                                            entryId: listElement.sys.id,
                                            fieldId: 'elementOrderTitle',
                                            locale: 'en-US',
                                        })}
                                        className="font-recoleta text-primary-200 lg:text-secondary-200 font-light text-[24px] pb-2 lg:pb-0 leading-[24px] lg:leading-[60px] lg:text-[60px]">
                                        {listElement.elementOrderTitle}
                                    </h3>
                                </div>
                                <div className="lg:col-start-5 lg:col-span-6 flex flex-col">
                                    {listElement.elementTitle && (
                                        <div
                                            {...ContentfulLivePreview.getProps({
                                                entryId: listElement.sys.id,
                                                fieldId: 'elementTitle',
                                                locale: 'en-US',
                                            })}
                                        >
                                            <Markdown
                                                className="font-recoleta text-secondary-200 lg:leading-[46px] lg:text-[46px]"
                                                headingStyle="smallHeadline"
                                                forcedBlock="heading-2"
                                                content={listElement.elementTitle} />
                                        </div>
                                    )}

                                    {listElement.elementParagraph && (
                                        <div
                                            {...ContentfulLivePreview.getProps({
                                                entryId: listElement.sys.id,
                                                fieldId: 'elementParagraph',
                                                locale: 'en-US',
                                            })}
                                        >
                                            <Markdown
                                                className="font-roboto text-secondary-200 mt-6 lg:mt-10 leading-[30px]"
                                                bodyVariant="body1"
                                                content={listElement.elementParagraph}
                                            />
                                        </div>

                                        )}
                                    <Separator className={cn("mt-6",
                                        index === verticalListSectionModel.listElementsCollection.items.length - 1 && ("hidden"))} />
                                </div>
                            </li>
                        )
                    })}
            </div>


            <div className="flex flex-col items-center w-full mb-20">
                <ButtonComponent 
                    contenfulEntryId={verticalListSectionModel.sys.id}
                    contentfulFieldId={"primaryCta"}
                    buttonVariant={"darkOutline"}
                    buttonSize="lg"
                    buttonModel={verticalListSectionModel.primaryCta}/>
            </div>
            

        </div>
    );
}

export default VerticalListSectionComponent;