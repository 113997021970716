import * as React from "react"

import {cva, type VariantProps} from "class-variance-authority"

import {cn} from "@/lib/utils"

const headerVariants = cva(
    "font-recoleta text-secondary-200",
    {
        variants: {
            styleVariant: {
                extraLarge:      "text-[128px] leading-[128px] font-recoleta lg:text-[192px] lg:leading-[192px]",
                large:           "text-[96px]  leading-[96px]  font-recoleta lg:text-[128px] lg:leading-[128px]",
                headline:        "text-[40px]  leading-[40px]  font-recoleta lg:text-[64px] lg:leading-[64px]",
                smallHeadline:   "text-[32px]  leading-[32px]  font-light font-recoleta lg:text-[60px] lg:leading-[60px]",
                subHeadline:     "text-[24px]  leading-[24px]  font-recoleta  lg:text-[46px] lg:leading-[46px]",
                smallSubHeadline: "text-[20px] font-light font-recoleta leading-[20px]",
                largeParagraph: "text-[22px] font-light font-roboto leading-[30px]",
                mainParagraph: "text-[16px] font-light font-roboto leading-[16px]",
                smallParagraph: "text-[12px] font-light font-roboto leading-[19px]",
            },
            typeVariant: {
                h1: "h1",
                h2: "h2",
                h3: "h3",
                h4: "h4",
                h5: "h5",
                h6: "h6",
            }
        },
        defaultVariants: {
            styleVariant: "headline",
            typeVariant: "h1"
        }
    }
)

export interface HeadingProps
    extends React.HTMLAttributes<HTMLHeadingElement>, VariantProps<typeof headerVariants>{
}

const Heading = React.forwardRef<HTMLHeadingElement, HeadingProps>(
    ({className, styleVariant, typeVariant, ...props}, ref) => {
        switch (typeVariant) {
            case "h1":
                return (
                    <h1
                        className={cn(
                            headerVariants({styleVariant: styleVariant}),
                            className
                        )}
                        ref={ref}
                        {...props}>
                    </h1>
                )
            case "h2":
                return (
                    <h2
                        className={cn(
                            headerVariants({styleVariant: styleVariant}),
                            className
                        )}
                        ref={ref}
                        {...props}>
                    </h2>
                )
            case "h3":
                return (
                    <h3
                        className={cn(
                            headerVariants({styleVariant: styleVariant}),
                            className
                        )}
                        ref={ref}
                        {...props}>
                    </h3>
                )
            case "h4":
                return (
                    <h4
                        className={cn(
                            headerVariants({styleVariant: styleVariant}),
                            className
                        )}
                        ref={ref}
                        {...props}>
                    </h4>
                )
            case "h5":
                return (
                    <h5
                        className={cn(
                            headerVariants({styleVariant: styleVariant}),
                            className
                        )}
                        ref={ref}
                        {...props}>
                    </h5>
                )
            case "h6":
                return (
                    <h6
                        className={cn(
                            headerVariants({styleVariant: styleVariant}),
                            className
                        )}
                        ref={ref}
                        {...props}>
                    </h6>
                )
            default:
                break;
        }

    }
)
Heading.displayName = "Heading"

export {Heading}