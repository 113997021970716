import { ContentfulLivePreview } from "@contentful/live-preview";
import { cn } from "@/lib/utils";
import { Paragraph } from "@/components/ui/texts/paragraph";

export interface MediaSectionModel {
    sys: {
        id: string;
    };
    __typename: string;
    caption: string | undefined;
    size: "Small" | "Medium" | "Large" | "Huge" | undefined;
    mediaItem: {
        contentType: string | undefined;
        url: string | undefined;
        fileName: string | undefined;
        description: string | undefined;
        title: string | undefined;
    } | undefined;

    colorScheme: "Racing Green" | "Bright Green" | "Sand" | "Dark" | "Light Green" | undefined;
}

const MediaSectionComponent: React.FC<MediaSectionModel> = (mediaSectionModel) => {
    let bgColor = "bg-primary-200";
    let textColor = "text-grey-900"

    switch (mediaSectionModel.colorScheme) {
        case "Dark":
            bgColor = "bg-grey-900"
            textColor = "text-secondary-200"
            break;
        case "Racing Green":
            bgColor = "bg-primary-800"
            textColor = "text-secondary-200"
            break;
        case "Bright Green":
            bgColor = "bg-primary-600"
            textColor = "text-secondary-200"
            break;
        case "Sand":
            bgColor = "bg-secondary-200"
            textColor = "text-grey-900"
            break;
        default:
            break;
    }

    const isImage = 
        mediaSectionModel.mediaItem?.contentType === "image/jpeg" || 
        mediaSectionModel.mediaItem?.contentType === "image/png" || 
        mediaSectionModel.mediaItem?.contentType === "image/svg+xml" ||
        mediaSectionModel.mediaItem?.contentType === "image/gif";

    const isVideo = mediaSectionModel.mediaItem?.contentType === "video/mp4" || 
        mediaSectionModel.mediaItem?.contentType === "video/quicktime" || 
        mediaSectionModel.mediaItem?.contentType === "video/ogg" || 
        mediaSectionModel.mediaItem?.contentType === "video/webm";

    return (
        <div className={cn("flex flex-col w-full items-center", bgColor)}>
            <div className="flex flex-row w-full max-w-[1440px] py-10 px-6 lg:px-[155px] gap-10 items-center justify-center">
                {mediaSectionModel.mediaItem && mediaSectionModel.mediaItem.url && (
                    <div
                        {...ContentfulLivePreview.getProps({
                            entryId: mediaSectionModel.sys.id,
                            fieldId: "paragraph",
                            locale: "en-US",
                        })}
                        className={cn(
                            "flex flex-row items-center w-full",
                            mediaSectionModel.size === "Large" && "w-[80%]",
                            mediaSectionModel.size === "Medium" && "w-[60%]",
                            mediaSectionModel.size === "Small" && "w-[40%]",)}>

                        {isVideo && (
                            <div className="w-full aspect-video flex flex-col">

                                <video
                                    className="w-full aspect-video rounded overflow-hidden"
                                    controls>
                                    <source src={mediaSectionModel.mediaItem.url} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <Paragraph 
                                    variant={"body3"} 
                                    className={cn("text-primary-900 w-full text-right pt-1", textColor)}>{mediaSectionModel.caption}</Paragraph>
                            </div>
                        ) || isImage && (
                            <div className="w-full aspect-video flex flex-col">
                                <img
                                    src={mediaSectionModel.mediaItem.url}
                                    alt={mediaSectionModel.mediaItem.description}
                                    className="w-full" />
                                { mediaSectionModel.caption && (
                                    <Paragraph 
                                        variant={"body3"} 
                                        className={cn("text-primary-900 w-full text-right pt-1", textColor)}>{mediaSectionModel.caption}</Paragraph>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
};

export default MediaSectionComponent;