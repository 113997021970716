import moment from 'moment';
import { Markdown } from '../markdown';
import { ContentfulLivePreview } from "@contentful/live-preview";
import { cn } from '@/lib/utils';
import { text } from 'stream/consumers';

export interface TextSectionModel {
    sys: {
        id: string;
    };
    __typename: string;
    articleText: ContentfulRichTextModel;
    headlineText: ContentfulRichTextModel;
    colorScheme: "Racing Green" | "Sand" | "Dark" | "Light Green" | undefined;
}


const TextSection: React.FC<TextSectionModel> = (textSectionModel) => {
    let bgColor = "bg-primary-200";
    let textColor = "text-grey-900"

    switch (textSectionModel.colorScheme) {
        case "Dark":
            bgColor = "bg-grey-900"
            textColor = "text-secondary-200"
            break;
        case "Racing Green":
            bgColor = "bg-primary-800"
            textColor = "text-secondary-200"
            break;
        case "Sand":
            bgColor = "bg-secondary-200"
            textColor = "text-grey-900"
            break;
        default:
            break;
    }

    return (
        <div className={cn("flex flex-col items-center w-full py-10", bgColor)}>
            <div className="flex flex-col w-full max-w-[1440px] px-6 lg:px-[155px]">
                <div 
                    {...ContentfulLivePreview.getProps({
                        entryId: textSectionModel.sys.id,
                        fieldId: 'headlineText',
                        locale: 'en-US',
                    })}
                    className="flex flex-col gap-10 font-roboto">
                    <Markdown
                        className={cn("text-center w-full", textColor)}
                        forcedBlock='heading-3'
                        content={textSectionModel.headlineText}/>
                </div>
                { textSectionModel.articleText && (
                    <div 
                        {...ContentfulLivePreview.getProps({
                            entryId: textSectionModel.sys.id,
                            fieldId: 'articleText',
                            locale: 'en-US',
                        })}
                        className={cn("flex flex-col gap-10 font-roboto", textColor)}>
                        <Markdown 
                            className={cn("w-full",
                            textSectionModel.headlineText?.json && "pt-10")}
                            content={textSectionModel.articleText}/>
                    </div>
                )}

            </div>
        </div>
    );
};

export default TextSection;