import { Heading } from '@/components/ui/Heading'
import { Paragraph } from '@/components/ui/texts/paragraph'
import { cn } from '@/lib/utils'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import Image from 'next/image'


function RichTextAsset({ id, assets }: { id: string, assets: ContentfulAssetModel[] | undefined }) {
    const asset = assets?.find((asset) => asset.sys.id === id)

    if (asset?.url) {
        return <Image src={asset.url} layout="fill" alt={asset.description} />
    }

    return null
}

export function Markdown({ content, className = "", bodyVariant = "body1", headingStyle = undefined, forcedBlock = undefined }: {
    content: ContentfulRichTextModel;
    className?: string;
    bodyVariant?: "body1" | "body2" | "body3";
    forcedBlock?: "heading-1" | "heading-2" | "heading-3" | "heading-4" | "heading-5" | "heading-6" | "paragraph" | undefined;
    headingStyle?: "extraLarge" | "headline" | "smallHeadline" | "subHeadline" | "smallSubHeadline" | "largeParagraph" | "mainParagraph" | "smallParagraph" | undefined;
}) {
    if (forcedBlock && content && content.json && content.json.content) {
        content.json.content.map((content: { nodeType: string }) => {
            content.nodeType = forcedBlock;
        })
    }

    if (!content || !content.json) {
        return null
    }

    return documentToReactComponents(content.json, {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node: any, children: any) => {
                return (
                    <p className="text-[80px]">{children}</p>
                )
            },

            [BLOCKS.HEADING_1]: (node: any, children: any) => (
                <Heading styleVariant={headingStyle ?? 'extraLarge'} typeVariant={'h1'} className={className}>
                    {children}
                </Heading>
            ),
            [BLOCKS.HEADING_2]: (node: any, children: any) => (
                <Heading styleVariant={headingStyle ?? 'headline'} typeVariant={'h2'} className={className}>
                    {children}
                </Heading>
            ),
            [BLOCKS.HEADING_3]: (node: any, children: any) => (
                <Heading styleVariant={headingStyle ?? 'smallHeadline'} typeVariant={'h3'} className={className}>
                    {children}
                </Heading>
            ),
            [BLOCKS.HEADING_4]: (node: any, children: any) => (
                <Heading styleVariant={headingStyle ?? 'subHeadline'} typeVariant={'h4'} className={className}>
                    {children}
                </Heading>
            ),
            [BLOCKS.HEADING_5]: (node: any, children: any) => (
                <Heading styleVariant={headingStyle ?? 'smallSubHeadline'} typeVariant={'h5'} className={className}>
                    {children}
                </Heading>
            ),
            [BLOCKS.HEADING_6]: (node: any, children: any) => (
                <Heading styleVariant={headingStyle ?? 'smallSubHeadline'} typeVariant={'h6'} className={className}>
                    {children}
                </Heading>
            ),
            [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
                return (<Paragraph
                    variant={bodyVariant}
                    className={className}>
                    {children}
                </Paragraph>)
            },
            [BLOCKS.UL_LIST]: (node: any, children: any) => {
                return <ul className={cn("list-disc", className)}>{children}</ul>
            },
            [BLOCKS.LIST_ITEM]: (node: any, children: any) => {
                return <li className={cn("list-disc ml-4", className)}>{children}</li>
            },
            [BLOCKS.OL_LIST]: (node: any, children: any) => {
                return <ol className={cn("list-decimal", className)}>{children}</ol>
            },
            [BLOCKS.QUOTE]: (node: any, children: any) => {
                return <blockquote className={cn("text-center text-[32px] leading-[32px] lg:text-[60px] font-light font-recoleta lg:leading-[60px] text-inherit", className)}>{children}</blockquote>
            },
            [BLOCKS.HR]: (node: any, children: any) => {
                return <hr className={cn("border-secondary-200", className)}>{children}</hr>
            },
            [BLOCKS.EMBEDDED_ENTRY]: (node: any, children: any) => {
                return <div className={cn("border-secondary-200", className)}>{children}</div>
            },
        },
    })
}