import { Markdown } from "../markdown";
import { Button } from "@/components/ui/button";
import Image from "next/image";
import { cn } from "@/lib/utils";
import { Paragraph } from "@/components/ui/texts/paragraph";
import { ButtonModel } from "@/lib/models/ContenfulSections";
import Link from "next/link";
import { ContentfulLivePreview } from "@contentful/live-preview";
import ButtonComponent from "../ButtonComponent";
import { Heading } from "@/components/ui/Heading";


export interface HorizontalContentSectionModel {
    sys: {
        id: string;
    };
    __typename: string;
    category: string;
    title: string | undefined;
    description: ContentfulRichTextModel;
    alignment: string | undefined;
    colorScheme: string | undefined;
    image: ContentfulImageModel;
    imageOrientation: boolean | undefined;
    primaryCta: ButtonModel | undefined;
}

const HorizontalContentSectionComponent: React.FC<HorizontalContentSectionModel> = (horizontalContentSectionModel) => {

    return (
        <div className={cn(
            "flex flex-row items-center justify-center h-auto w-full bg-secondary-200",
            horizontalContentSectionModel.colorScheme === "Dark" && "bg-background-900"
        )}>
            <div className={cn(
                "flex flex-row items-center w-full py-10 lg:py-20 max-w-[1440px] px-6 gap-10 lg:px-[155px] lg:gap-[8%]",
                horizontalContentSectionModel.alignment === "Left" && " flex-row-reverse"
            )}>
                {/* Text Column */}
                <div className="flex-1 flex flex-col justify-start">
                    {horizontalContentSectionModel.category && (
                        <Heading
                            {...ContentfulLivePreview.getProps({
                                entryId: horizontalContentSectionModel.sys.id,
                                fieldId: "category",
                                locale: "en-US",
                            })}
                            typeVariant={"h5"}
                            styleVariant={"mainParagraph"}
                            className={cn("text-gray-900 mb-6", horizontalContentSectionModel.colorScheme === "Dark" && "text-primary-200")}>
                            {horizontalContentSectionModel.category}
                        </Heading>
                    )}

                    {horizontalContentSectionModel.title && (
                        <Heading
                            styleVariant={"subHeadline"}
                            typeVariant={"h2"}
                            {...ContentfulLivePreview.getProps({
                                entryId: horizontalContentSectionModel.sys.id,
                                fieldId: "title",
                                locale: "en-US",
                            })}
                            className={cn(
                                "text-grey-900 mb-10",
                                horizontalContentSectionModel.colorScheme === "Dark" && "text-secondary-200"
                            )}>
                            {horizontalContentSectionModel.title}
                        </Heading>
                    )}

                    {horizontalContentSectionModel.description && (
                        <div
                            {...ContentfulLivePreview.getProps({
                                entryId: horizontalContentSectionModel.sys.id,
                                fieldId: "description",
                                locale: "en-US",
                            })}>
                            <Markdown 
                                bodyVariant="body1"
                                className={cn(
                                    "text-grey-900 font-light",
                                    horizontalContentSectionModel.colorScheme === "Dark" && "bg-background-900 text-secondary-200",
                                    horizontalContentSectionModel.primaryCta && "mb-10"
                            )}
                                content={horizontalContentSectionModel.description} />
                        </div>
                    )}

                    {horizontalContentSectionModel.primaryCta && (
                        <div className="flex flex-row space-x-2">
                            <ButtonComponent 
                                contenfulEntryId={horizontalContentSectionModel.sys.id}
                                contentfulFieldId={"primaryCta"}
                                buttonSize="lg"
                                buttonVariant={horizontalContentSectionModel.colorScheme === "Dark" ? "darkOutline" : "lightOutline"}
                                buttonModel={horizontalContentSectionModel.primaryCta}/>

                        </div>
                    )}

                </div>
                {/* Image Column */}
                <div className={cn(
                    "hidden lg:flex px-0 lg:px-11 max-w-[350px] lg:max-w-[900px]",
                    horizontalContentSectionModel.image && horizontalContentSectionModel.image.url && "rounded-lg py-0 lg:py-14 bg-background-900",
                    horizontalContentSectionModel.image && horizontalContentSectionModel.image.url && horizontalContentSectionModel.colorScheme === "Dark" && "lg:bg-secondary-100",
                )}>
                    {horizontalContentSectionModel.image && horizontalContentSectionModel.image.url && (
                        <Image
                            {...ContentfulLivePreview.getProps({
                                entryId: horizontalContentSectionModel.sys.id,
                                fieldId: "image",
                                locale: "en-US",
                            })}
                            placeholder="empty"
                            src={horizontalContentSectionModel?.image?.url}
                            alt={""}
                            className={cn("rounded-lg aspect-[3/4] object-cover", horizontalContentSectionModel.imageOrientation == false && "aspect-[4/3]") }
                            width={horizontalContentSectionModel.imageOrientation == false ? 440 : 355 }
                            height={horizontalContentSectionModel.imageOrientation == false ? 330 : 472 } 
                        />
                    )}

                </div>
            </div>
        </div>
    );
};

export default HorizontalContentSectionComponent;