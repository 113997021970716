import * as React from "react"
import {Slot} from "@radix-ui/react-slot"
import {cva, type VariantProps} from "class-variance-authority"

import {cn} from "@/lib/utils"
import { LoaderCircle } from "lucide-react"

const buttonVariants = cva(
    "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
    {
        variants: {
            variant: {
                outline:
                    "border border-primary-900 text-primary-900 hover:bg-secondary-100 hover:bg-opacity-70 hover:border-opacity-10 hover:border-primary-900 disabled:opacity-40 disabled:border-opacity-40",
                ghost: "hover:bg-accent hover:text-accent-foreground",

                highlightFilled:
                    "bg-neutral-950 text-neutral-100 hover:bg-opacity-80 disabled:bg-opacity-40",
                lightFilled:
                    "bg-neutral-950 text-neutral-100 hover:bg-neutral-800 disabled:bg-opacity-40",
                darkFilled:
                    "bg-primary-200 text-grey-900 hover:bg-primary-100 disabled:bg-opacity-40",
                dangerFilled:
                    "border-red-400 bg-red-500 text-white/90 hover:bg-red-600 hover:text-white",

                highlightOutline:
                    "border border-primary-900 text-primary-900 hover:bg-secondary-100 hover:bg-opacity-70 hover:border-opacity-10 hover:border-primary-900 disabled:opacity-40 disabled:border-opacity-40",
                lightOutline:
                    "border border-primary-900 text-primary-900 hover:bg-secondary-100 disabled:opacity-40 disabled:border-opacity-40",
                darkOutline:
                    "border border-secondary-200 text-secondary-200 hover:bg-primary-200 hover:text-primary-900 hover:border-opacity-0 disabled:border-opacity-40 disabled:opacity-40",

                highlightText:
                    "text-primary-900 hover:underline hover:underline-offset-4 disabled:opacity-40",
                lightText:
                    "text-primary-900 hover:underline hover:underline-offset-4 disabled:opacity-40",
                darkText:
                    "text-secondary-200 hover:underline hover:underline-offset-4 disabled:opacity-40",
                dangerText: "text-red-500 hover:underline hover:underline-offset-4 disabled:opacity-40",

                icon: "text-white/70 hover:text-white disabled:opacity-40 border border-transparent hover:border-primary-200",

            },
            size: {
                xs: "px-3 py-1.25 font-light",
                sm: "px-5 py-2",
                md: "px-8 py-2",
                lg: "px-6 py-4",
                snug: "p-0",
                icon: "h-10 w-10",
                iconText: "px-4 py-2",
            },
        },
        defaultVariants: {
            variant: "highlightFilled",
            size: "lg",
        },
    }
)

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    asChild?: boolean
    isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className, variant, size, asChild = false, isLoading = false, ...props }, ref) => {
        const Comp = asChild ? Slot : "button";
        return (
            <Comp
                className={cn(
                    buttonVariants({ variant, size, className }),
                    isLoading && "cursor-not-allowed opacity-70" // Apply styles for loading state
                )}
                ref={ref}
                disabled={isLoading || props.disabled} // Disable the button while loading
                {...props}
            >
                {isLoading ? (
                    <div className="flex justify-center items-center">
                        <LoaderCircle className="w-4 h-4 animate-spin duration-1000" />
                    </div>
                ) : (
                    props.children
                )}
            </Comp>
        );
    }
);
Button.displayName = "Button"

export {Button, buttonVariants}
