import { cn } from "@/lib/utils";
import { Markdown } from "../markdown";
import { ContentfulLivePreview } from "@contentful/live-preview";

export interface TitleSectionModel {
    sys: {
        id: string;
    }
    __typename: string;
    sectionTitle: ContentfulRichTextModel;
    size: "Large" | "Medium" | "Small";
    alignment: "Left" | "Center";
}

const TitleSectionComponent: React.FC<TitleSectionModel> = (titleSectionModel) => {
    if (titleSectionModel.size === "Large") {
        return (
            <div
                {...ContentfulLivePreview.getProps({
                    entryId: titleSectionModel.sys.id,
                    fieldId: 'sectionTitle',
                    locale: 'en-US',
                })}
                className="max-w-[1440px] w-full flex align-middle justify-center">
                <Markdown
                    forcedBlock="heading-2"
                    className={cn("w-full pt-20 pb-10 px-6 text-center",
                        titleSectionModel.alignment === "Left" && "text-left"
                    )}
                    content={titleSectionModel.sectionTitle} />
            </div>
        )
    }

    if (titleSectionModel.size === "Medium") {
        return (
            <div 
                {...ContentfulLivePreview.getProps({
                    entryId: titleSectionModel.sys.id,
                    fieldId: 'sectionTitle',
                    locale: 'en-US',
                })}
                className="max-w-[1440px] w-full flex align-middle justify-center">
                <Markdown
                    forcedBlock="heading-3"
                    className={cn("w-full pt-20 pb-10 px-6 text-center",
                        titleSectionModel.alignment === "Left" && "text-left"
                    )}
                    content={titleSectionModel.sectionTitle} />
            </div>
        )
    }

    if (titleSectionModel.size === "Small") {
        return (
            <div 
                {...ContentfulLivePreview.getProps({
                    entryId: titleSectionModel.sys.id,
                    fieldId: 'sectionTitle',
                    locale: 'en-US',
                })}
                className="max-w-[1440px] w-full flex align-middle justify-center">
                <Markdown
                    forcedBlock="heading-4"
                    className={cn("w-full pt-20 pb-10 px-6 text-center",
                        titleSectionModel.alignment === "Left" && "text-left"
                    )}
                    content={titleSectionModel.sectionTitle} />
            </div>
        )
    }
}

export default TitleSectionComponent;