import { Button } from "@/components/ui/button";
import { ButtonModel } from "@/lib/models/ContenfulSections";
import { cn } from "@/lib/utils";
import Link from "next/link";
import { Markdown } from "../markdown";
import { ContentfulLivePreview } from "@contentful/live-preview";
import { Switch } from "@/components/ui/switch";
import React from "react";
import ButtonComponent from "../ButtonComponent";
import { Carousel, CarouselContent, CarouselItem } from "@/components/ui/carousel";

export interface SubscriptionPriceSectionModel {
    sys: {
        id: string;
    };
    __typename: string;
    headlineText: ContentfulRichTextModel | undefined;
    paragraphText: ContentfulRichTextModel | undefined;
    backgroundImage: ContentfulImageModel | undefined;
    ctaText: string;
    subscriptionPackagesCollection: {
        items: SubscriptionPackageModel[]
    };
}

export interface SubscriptionPackageModel {
    sys: {
        id: string;
    };
    __typename: string;
    subscriptionName: string;
    subscriptionDescription: ContentfulRichTextModel | undefined;
    highlighted: boolean | undefined;
    quarterlyPrice: string;
    yearlyPrice: string;
    quarterlyUserPrice: string;
    yearlyUserPrice: string;
    primaryCta: ButtonModel | undefined;
    packageFeaturesParagraph: ContentfulRichTextModel | undefined;
    paragraphFeaturesRight: ContentfulRichTextModel | undefined;
}

const SubscriptionPriceSection: React.FC<SubscriptionPriceSectionModel> = (subscriptionPriceSectionModel) => {

    const [isYearly, switchSubPeriod] = React.useState(true)

    function switchSubPeriodView() {
        switchSubPeriod(!isYearly)
    }

    return (
        <div
            key={subscriptionPriceSectionModel.sys.id}
            style={{ backgroundImage: `url('${subscriptionPriceSectionModel.backgroundImage?.url ?? ''}')` }}
            className={cn("flex flex-col items-center w-full lg:px-6 pb-10",
                subscriptionPriceSectionModel.backgroundImage
                && ("bg-fill bg-no-repeat bg-center bg-contain"))
            }
        >
            <div className="flex flex-col items-center w-full max-w-[1440px]">
                <div className="z-10 max-w-[1440px] w-full flex flex-col items-center pt-10 lg:pt-[144px]">
                    {subscriptionPriceSectionModel.headlineText && (
                        <div
                            {...ContentfulLivePreview.getProps({
                                entryId: subscriptionPriceSectionModel.sys.id,
                                fieldId: "headlineText",
                                locale: "en-US",
                            })}>
                            <Markdown
                                className="text-center max-w-[700px] text-secondary-200 whitespace-break-spaces"
                                headingStyle="headline"
                                forcedBlock="heading-1"
                                content={subscriptionPriceSectionModel.headlineText} />
                        </div>
                    )}
                    {subscriptionPriceSectionModel.paragraphText && (
                        <div
                            className="px-6 lg:px-0 lg:max-w-[33%] pt-6"
                            {...ContentfulLivePreview.getProps({
                                entryId: subscriptionPriceSectionModel.sys.id,
                                fieldId: "paragraphText",
                                locale: "en-US",
                            })}>
                            <Markdown
                                className="text-center text-secondary-200 whitespace-break-spaces"
                                forcedBlock="heading-4"
                                headingStyle="largeParagraph"
                                content={subscriptionPriceSectionModel.paragraphText} />
                        </div>
                    )}
                    <span className="pt-6 flex flex-row items-center">
                        <p className={cn("font-roboto text-[20px] p-4 transition-opacity", isYearly && "opacity-50")}>Quarterly</p>
                        <Switch checked={isYearly} onCheckedChange={switchSubPeriodView} />
                        <p className={cn("font-roboto text-[20px] p-4 transition-opacity", !isYearly && "opacity-50")}>Yearly</p>
                    </span>
                    <div {...ContentfulLivePreview.getProps({
                        entryId: subscriptionPriceSectionModel.sys.id,
                        fieldId: "ctaText",
                        locale: "en-US",
                    })}>
                        <h6 className="text-[16px] opacity-80">{subscriptionPriceSectionModel.ctaText}</h6>
                    </div>
                    <div className="hidden max-w-[1440px] lg:flex lg:flex-row w-full justify-between gap-4 my-10">
                        {subscriptionPriceSectionModel &&
                            subscriptionPriceSectionModel.subscriptionPackagesCollection &&
                            subscriptionPriceSectionModel.subscriptionPackagesCollection.items.map((subscriptionPackage, index) => (
                                <PriceElementCardElement 
                                    className=""    
                                    key={subscriptionPackage.sys.id} {...subscriptionPackage} isYearly={isYearly}/>
                            ))}
                    </div>
                    <div className="flex flex-row lg:hidden w-full pt-6 lg:pt-0">
                        <Carousel className="w-full">
                            <CarouselContent>
                                {subscriptionPriceSectionModel &&
                                    subscriptionPriceSectionModel.subscriptionPackagesCollection &&
                                    subscriptionPriceSectionModel.subscriptionPackagesCollection.items.map((subscriptionPackage, index) => (
                                        <CarouselItem 
                                            key={subscriptionPackage.sys.id}
                                            className="flex flex-col lg:items-center justify-center items-start w-full max-w-[360px]">
                                            <PriceElementCardElement 
                                                className={cn(index == 0 && "ml-6", index === subscriptionPriceSectionModel.subscriptionPackagesCollection.items.length-1 && "mr-6")}  
                                                key={subscriptionPackage.sys.id} {...subscriptionPackage} isYearly={isYearly}/>
                                        </CarouselItem>
                                    ))}
                        
                            </CarouselContent>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    );
};

const PriceElementCardElement:  React.FC<SubscriptionPackageModel & { isYearly: boolean, className: string }> = (subscriptionPackage) => {
    const { isYearly, className } = subscriptionPackage;

    return (
        <div key={subscriptionPackage.sys.id}
            className={cn("backdrop-blur-md border border-stone-50/20 rounded-lg px-6 py-10 pb-10 flex-1 mt-10",
                subscriptionPackage.highlighted && ("bg-secondary-100 text-grey-900 mt-0 pt-4"),
                className)
            }>
            {subscriptionPackage.highlighted && (
                <p className="font-roboto font-normal pb-4 lg:pb-10 w-full text-center text-grey-900">Most Value</p>
            )}
            <h3
                {...ContentfulLivePreview.getProps({
                    entryId: subscriptionPackage.sys.id,
                    fieldId: "subscriptionName",
                    locale: "en-US",
                })}
                className="font-recoleta text-[40px] leading-[40px] lg:text-[60px] lg:leading-[60px]">{subscriptionPackage.subscriptionName}</h3>
            <div className="pt-2 h-[60px]">
                {subscriptionPackage.subscriptionDescription && (
                    <div  {...ContentfulLivePreview.getProps({
                        entryId: subscriptionPackage.sys.id,
                        fieldId: "subscriptionDescription",
                        locale: "en-US",
                    })}>
                        <Markdown
                            className={cn("font-roboto text-[14px] text-secondary-100 whitespace-break-spaces opacity-80", subscriptionPackage.highlighted && "text-grey-900 opacity-100")}
                            forcedBlock="paragraph"
                            content={subscriptionPackage.subscriptionDescription} />
                    </div>

                )}
            </div>

            <p className="pt-4 lg:pt-10 pb-4 text-opacity-50 opacity-50">From</p>
            <span className="font-recoleta flex flex-row align-bottom items-end">
                {isYearly && (
                    <h2
                        {...ContentfulLivePreview.getProps({
                            entryId: subscriptionPackage.sys.id,
                            fieldId: "yearlyPrice",
                            locale: "en-US",
                        })}
                        className={cn("text-[56px] leading-[56px] lg:text-[70px] lg:leading-[70px] transition",
                            subscriptionPackage.highlighted && (" text-grey-900 "))}>
                        {subscriptionPackage.yearlyPrice}
                    </h2>
                )}
                {!isYearly && (
                    <h2
                        {...ContentfulLivePreview.getProps({
                            entryId: subscriptionPackage.sys.id,
                            fieldId: "quarterlyPrice",
                            locale: "en-US",
                        })}
                        className="text-[56px] leading-[56px] lg:text-[70px] lg:leading-[70px] transition">
                        {subscriptionPackage.quarterlyPrice}
                    </h2>
                )}

                <p className="font-roboto text-[16px] leading-[16px] ">&nbsp; /month</p>
            </span>
            {isYearly && (
                <h4
                    {...ContentfulLivePreview.getProps({
                        entryId: subscriptionPackage.sys.id,
                        fieldId: "yearlyUserPrice",
                        locale: "en-US",
                    })}
                    className="font-recoleta text-[16px] opacity-80 mt-2">
                    +{subscriptionPackage.yearlyUserPrice} for additional users
                </h4>
            )}
            {!isYearly && (
                <h4
                    {...ContentfulLivePreview.getProps({
                        entryId: subscriptionPackage.sys.id,
                        fieldId: "quarterlyUserPrice",
                        locale: "en-US",
                    })}
                    className="font-recoleta text-[16px] opacity-80 mt-2">
                    +{subscriptionPackage.quarterlyUserPrice} for additional users
                </h4>
            )}

            <ButtonComponent
                className="mt-2 lg:mt-10"
                contenfulEntryId={subscriptionPackage.sys.id}
                contentfulFieldId={"primaryCta"}
                buttonVariant={subscriptionPackage.highlighted ? "lightFilled" : "darkFilled"}
                buttonSize={"lg"}
                buttonModel={subscriptionPackage.primaryCta} />
            <div
                {...ContentfulLivePreview.getProps({
                    entryId: subscriptionPackage.sys.id,
                    fieldId: "packageFeaturesParagraph",
                    locale: "en-US",
                })}
                className="grid columns-1 rows-1">

                {subscriptionPackage.packageFeaturesParagraph && (
                    <div className="w-full grid-cols-1 grid-rows-1 row-start-1 row-end-1 my-4 lg:my-10 ">
                        <Markdown
                            className={cn("text-secondary-200 text-[12px] leading-[19px]   ", subscriptionPackage.highlighted && "text-grey-900")}
                            forcedBlock="paragraph"
                            content={subscriptionPackage.packageFeaturesParagraph}
                        />
                    </div>

                )}
                {subscriptionPackage.paragraphFeaturesRight && (
                    <div className="w-full grid-cols-1 grid-rows-1 row-start-1 row-end-1 my-4 lg:my-10 flex flex-col">
                        <Markdown
                            className={cn("text-secondary-200 0 text-[12px] leading-[19px] text-right ", subscriptionPackage.highlighted && "text-grey-900")}
                            forcedBlock="paragraph"
                            content={subscriptionPackage.paragraphFeaturesRight}
                        />
                    </div>
                )}
            </div>

        </div>
    )
}

export default SubscriptionPriceSection;